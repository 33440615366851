@import "@/styles/_variables.scss";

.tgn-avatar {
  position: relative;
  & > div {
    display: block !important;
  }

  &.size-64 {
    img {
      height: 64px;
      width: 64px;
    }
  }

  &.size-40 {
    img {
      height: 40px;
      width: 40px;
    }
  }

  &.size-32 {
    img {
      height: 32px;
      width: 32px;
    }
  }

  &.size-24 {
    img {
      height: 24px;
      width: 24px;
    }
  }

  &.size-20 {
    img {
      height: 20px;
      width: 20px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    border-radius: 100px;
  }
}

.active {
  box-sizing: content-box;
  border: 1px solid #fff;
  position: absolute;
  bottom: -1px;
  right: -1px;
  background: #4bca81;
  border-radius: 50%;
  border: 1px solid $color-white;
}
