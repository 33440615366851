@import "@/styles/_variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  border-bottom: 1px solid $color-gray-1;
  gap: 1.5rem;
}

.search-input {
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  &::placeholder {
    color: $text-tertiary-color-gray-5;
  }
}

.text-gray-05 {
  color: $text-tertiary-color-gray-5;
}

.overlay {
  position: fixed;
  left: 52px;
  top: calc(48px + 56px + 1px);
  height: calc(100vh - 48px - 56px);
  width: 348px;
  background: $color-white;
  z-index: 12;
  transform: translateX(0);
  transition: all 0.25s;
}

.search-item {
  font-size: 14px;
  color: $text-secondary-color-gray-7;
  padding: 10px 24px;
  cursor: pointer;
  &:hover {
    background: #e8e8e866;
  }
}
.active {
  background: #e8e8e866;
}

.input-search {
  position: relative;
  width: calc(348px - 24px);
}
.hidden-bar {
  transform: translateX(-100%);
}

.result {
  padding: 12px 0;
  border-bottom: 1px solid $color-gray-1;
}

.btn-remove {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.setting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: var(--bs-gray-100);
  cursor: pointer;
  i {
    font-size: 1rem;
  }
}

.body-popup {
  width: 240px;
  border-radius: 8px;
  border: 0.5px solid var(--bs-gray-100);
  background: var(--bs-white);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  & + div {
    opacity: 0;
  }
}

.id {
  display: inline-block;
  max-width: 200px;
}

.description {
  font-size: 12px;
  color: #161616;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
}
