@import "@/styles/_variables.scss";

.search {
  display: flex;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: $color-white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  i {
    color: #19161d;
  }
}
