.wrapper {
  width: 476px;
}
.mt-48 {
  margin-top: 3rem;
}

.timeline {
  position: relative;
  border-left: 1px solid #e5e5e5;
  padding-top: 1.5rem;
  margin-left: 15px;
  padding-left: 28px;
  &::after {
    position: absolute;
    content: "";
    top: 2rem;
    left: -5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #d9d9d9;
  }
}

.update-line {
  display: flex;
  gap: 12px;
}
