@import "@/styles/_variables.scss";

.peek-renderer {
  position: fixed;
  right: 0;
  top: 0;
  width: 428px;
  max-width: 100%;
  transform: translateX(100%);
  background: $color-white;
  height: 100vh;
  overflow-y: auto;
  max-height: 100vh;
  box-shadow: 0px 2px 8px 0px rgba(52, 60, 68, 0.1),
    0px 0px 2px 0px rgba(117, 131, 142, 0.1);
  transition: all 0.25s;
  overflow-x: hidden;
  z-index: 10;
}

.active {
  transform: translateX(0);
}

.show-full {
  width: 100%;
}

.header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 2;
  transition: all 0.2s linear;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    top: 100%;
    background: rgba(232, 232, 232, 0.4);
    z-index: 2;
  }
}
.active-header {
  background: $color-black;
}

.input-title {
  &:focus {
    border: 1px solid $color-gray-1;
    outline: none;
    border-radius: 4px;
  }
}

.left {
  width: 70%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    background-color: $link-water;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: $color-gray-1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-1;
    border-radius: 100px;
  }
}

.extend-left {
  width: 100%;
}

@keyframes reveal {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.log {
  width: 30%;
  border-left: 1px solid $color-gray-1;
  transition: all 0.3s;
  animation: reveal 0.3s forwards;
}

.header-left {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(70% + 2px);
  padding-left: 24px;
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
}

.hide-border {
  border-right: unset;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.header-sidebar {
  margin-bottom: 24px;
}

.bg-linear {
  height: 264px;
  width: 100%;
  margin-top: -48px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 13.81%,
      rgba(0, 0, 0, 0) 100%
    ),
    #0064ee;
}

.tooltip {
  display: inline-block;
  position: relative;
  margin: -1rem !important;
  padding: 4px 8px;
  background: var(--bs-dark);
  z-index: 10 !important;
  color: var(--bs-white);
  border-radius: 8px;
}
