@import "@/styles/_variables.scss";

.tgn-switch,
.tgn-radio,
.tgn-checkbox {
  &.form-switch {
    .form-check-input[type="checkbox"] {
      border-color: #d0d0d0;
      background-color: $link-water;
      background-image: url("../../assets/icons/switch-icon.svg");
      height: 24px;
      width: 48px;
    }
  }
  &.form-check-inline {
    .form-check-input[type="radio"] {
      border-color: #d0d0d0;
      background-color: $color-white;
      background-size: 18px 17px;
      height: 24px;
      width: 24px;
      &:checked {
        background-image: url("../../assets/icons/switch-icon.svg");
      }
    }
  }
  &.form-check-inline {
    .form-check-input[type="checkbox"] {
      border-color: #d0d0d0;
      background-color: $color-white;
      background-size: 18px 17px;
      height: 24px;
      width: 24px;
      &:checked {
        background-image: url("../../assets/icons/switch-icon.svg");
      }
    }
  }
  &.form-check {
    display: flex;
    align-items: center;
    .form-check-input {
      filter: unset;
      float: unset;
      margin-top: 0;
      margin-right: 8px;
      &:hover {
        border-color: $color-gray-6;
        background-color: $link-water;
      }
      &:focus {
        border-color: $color-background-selection;
        outline: 0;
        box-shadow: 0 0 0 2px $color-background-selection;
      }
      &:checked {
        background-color: $brand-secondary;
        border-color: $brand-secondary;
        &:hover {
          border-color: $brand-accessible;
          background-color: $brand-accessible;
        }
        &:focus {
          border-color: $brand-secondary;
          outline: 0;
          box-shadow: 0 0 0 2px $color-background-selection;
        }
      }
      &:disabled {
        opacity: 0.2;
        &:checked {
          background-color: $brand-primary;
          border-color: $brand-primary;
        }
      }
    }
    .form-check-label {
      color: $text-primary-color-gray-10;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.dark {
  input {
    &:checked {
      background-color: $text-primary-color-gray-10 !important;
      border-color: $text-primary-color-gray-10 !important;
    }
    &:focus {
      border-color: $text-primary-color-gray-10;
      box-shadow: 0 0 0 0.25rem rgba($text-primary-color-gray-10, 0.2) !important;
    }
  }
}

.tgn-switch {
  input {
    margin-top: 0;
  }
}
