@import "@/styles/_variables.scss";

.groups {
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background-color: $link-water;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: $color-gray-1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-1;
    border-radius: 100px;
  }
}

.avatar {
  border-radius: 4px;
}

.popup {
  border-radius: 8px;
  border: 0.5px solid #e8e8e8;
  background: $color-white;
  // box-shadow: $box-shadow;
}
