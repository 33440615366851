@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.react-multi-carousel-dot {
  button {
    border: none !important;
    background-color: #f3f4f6;
    width: 6px !important;
    height: 6px !important;
  }
}
.react-multi-carousel-dot--active {
  button {
    border: none;
    background-color: #111827;
  }
}
