@import "@/styles/_variables.scss";

.tgn-select {
  label {
    color: #525252;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
  }
  select {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 11px 16px;
  }
  span {
    margin-top: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.btn-select {
  display: flex;
  align-items: center;
  background: white;
  color: $text-primary-color-gray-10;
  padding: 8px 12px;
  cursor: pointer;
  &:hover,
  &:active {
    background: none;
    color: $text-primary-color-gray-10;
  }
}

.outlined {
  border-radius: 4px;
  border: 1px solid $color-gray-1;
}

.text {
  border: none;
  padding: 0;
}
