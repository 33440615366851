@import "@/styles/_variables.scss";

.diagramContainer {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  transition: all 1s;
  svg {
    background: url("../../../../assets/images/dot-pattern-bgr.png");
  }
}

.diagramWrapper {
  background: #fff;
  position: fixed;
  top: 0;
  left: 52px;
  width: 100%;
  height: 100vh;
}
.nodeSettings {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: lightgray;
}

.input-area {
  visibility: hidden;
  position: absolute;
  border-radius: 10px;
  background: #96db98;
  border: none;
  outline: none;
  resize: none;
  min-width: 30px;
  max-width: 400px;
  font-size: 1rem;
  padding: 6px 16px;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
}

.drag-node {
  max-width: 232px;
  opacity: 0.3;
  &-content {
    background: #c7c7e8;
    min-width: 30px;
    max-width: 400px;
    font-size: 1rem;
    border-radius: 10px;
    p {
      margin: 0;
    }
  }
}

.text-node {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 292px;
  white-space: break-spaces;
  font-weight: 600;
  font-size: 14px;
  border-radius: 1rem;
  border: 2px solid #6a6a9f;
  background: $color-white;
  border-radius: 16px;
  border: 1px solid #c7c7e8;
  background: $color-white;

  /* Test */
  box-shadow: 0px 1px 4px 0px rgba(199, 199, 232, 0.04),
    0px 4px 8px 0px rgba(199, 199, 232, 0.06),
    0px 8px 16px 0px rgba(199, 199, 232, 0.08),
    0px 16px 32px 0px rgba(199, 199, 232, 0.08),
    0px 32px 50px 0px rgba(199, 199, 232, 0.12),
    0px 40px 84px 0px rgba(199, 199, 232, 0.12);
  &-active {
    border-radius: 16px;
    border: 2px solid #6a6a9f;
    background: $color-white;
    box-shadow: 0px 1px 4px 0px rgba(199, 199, 232, 0.04),
      0px 4px 8px 0px rgba(199, 199, 232, 0.06),
      0px 8px 16px 0px rgba(199, 199, 232, 0.08),
      0px 16px 32px 0px rgba(199, 199, 232, 0.08),
      0px 32px 50px 0px rgba(199, 199, 232, 0.12),
      0px 40px 84px 0px rgba(199, 199, 232, 0.12);
  }
  p {
    width: max-content;
    max-width: 230px;
    pointer-events: none;
    margin: 0;
    overflow-wrap: break-word;
  }
}

.text-node-root {
  max-width: 180px;
  min-width: 100px;
}

.skeleton {
  visibility: hidden;
  position: absolute;
  background: #c7c7e8;
  opacity: 0.4;
  border-radius: 4px;
  height: 14px;
  width: 46px;
}

.avatar {
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid transparent;
  padding: 1px;
}

.more-number {
  color: $color-gray-10;
  font-size: 13px;
}

.file {
  border: 1px dashed #e4e6ef;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  column-gap: 4px;
  background: white;
}

.amount-file {
  font-size: 13px;
  font-weight: 700;
  color: $color-gray-10;
}

.btn-add {
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  background-color: #c7c7e9;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b6aa1;
}

.btn-toggle {
  position: absolute;
  visibility: hidden;
  width: 24px;
  height: 24px;
  border: 1px solid #b3b3b3;
  color: #b3b3b3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-white;
}

.tag {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

.date {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  &::before {
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 38px;
    width: 1px;
    background-color: $color-gray-1;
  }
}

.task-complete {
  white-space: nowrap;
  column-gap: 8px;
}

.progress {
  position: relative;
  width: 24px;
  height: 24px;
  background: $link-water;
  border-radius: 50%;
  &::before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $color-white;
  }
}

.flag {
  position: absolute;
  right: 0;
  left: 0;
  path {
    fill: $brand-primary;
  }
}

.title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: $brand-secondary-blue;
  font-weight: 600;
}

.description {
  font-size: 12px;
  color: $text-primary-color-gray-10;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
}
@keyframes width-animation {
  from {
    left: 0;
    width: 100%;
  }
  to {
    left: calc(52px + 348px);
    width: calc(100% - 52px - 348px);
  }
}

@keyframes width-reverse-animation {
  from {
    left: calc(52px + 348px);
    width: calc(100% - 52px - 348px);
  }
  to {
    left: 0;
    width: 100%;
  }
}

.show-search-bar {
  left: calc(52px + 348px);
  width: calc(100% - 52px - 348px);
  animation-name: width-animation;
  animation-duration: 0.25s;
}
.hide-search-bar {
  left: 0;
  width: 100%;
  animation-name: width-reverse-animation;
  animation-duration: 0.25s;
}
