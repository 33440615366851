@import "@/styles/_variables.scss";

.icon {
  display: flex;
  height: 48px;
  align-items: center;
}

.overlay {
  background: white;
  border-radius: 8px;
  border: 0.5px solid $color-gray-1;
  background: $color-white;
  box-shadow: 0px 2px 8px 0px rgba(52, 60, 68, 0.1),
    0px 0px 2px 0px rgba(117, 131, 142, 0.1);
  padding: 1rem;
  margin-bottom: 6px;
}

.btn-icon {
  position: relative;
  height: 48px;
}

.tooltip {
  position: absolute;
  font-size: 12px;
  padding: 8px;
  bottom: calc(100% + 8px);
  left: 0;
  white-space: nowrap;
  border-radius: 4px;
  background: $color-white;
  box-shadow: 0px 2px 8px 0px rgba(52, 60, 68, 0.1),
    0px 0px 2px 0px rgba(117, 131, 142, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}
.active {
  opacity: 1 !important;
  visibility: visible !important;
}

.action-disable {
  pointer-events: none;
  color: var(--bs-gray-400);
}
