@import "@/styles/_variables.scss";

.drawer {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.show {
  opacity: 1;
  visibility: visible;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 98;
}

.content {
  background: $color-white;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  transform: translateX(100%);
  transition: all 0.25s linear;
  z-index: 99;
}
.active {
  transform: translateX(0);
}
