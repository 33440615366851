@import "@/styles/_variables.scss";

.wrapper {
  padding: 48px;
  flex: 1;
}

.tabs {
  flex: 1;
  border-bottom: 1px solid $color-gray-1;
  li {
    margin-right: 1.5rem;
    button {
      position: relative;
      border: none !important;
      color: $text-primary-color-gray-10;
      padding: 14px 8px;
      font-size: 14px;
      &:hover {
        color: $text-primary-color-gray-10;
      }
      &[aria-selected="true"]::before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 4px 4px 0px 0px;
        background: $color-secondary;
      }
    }
  }
}

.setting {
  position: absolute;
  right: 0;
  top: 23px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  gap: 24px;
}

.name {
  word-break: break-all;
  outline: none;
  border: none;
  border-radius: 8px;
  &:hover {
    background: $color-gray-1;
  }
}
