$color-secondary: #161616;
$enable-negative-margins: true;
$color-background-image-overlay: rgba(0, 0, 0, 0.6);
$shadow-drag: rgba(0, 0, 0, 0.4);
$shadow-drop-down: rgba(0, 0, 0, 0.16);
$color-background-backdrop: rgba(255, 255, 255, 0.75);
$color-text-link-inverse-active: rgba(255, 255, 255, 0.5);
$color-text-link-inverse-disabled: rgba(255, 255, 255, 0.15);
$color-white: #ffffff;
$color-black: #000000;
$color-gray-1: #e8e8e8;
$color-gray-2: #d0d0d0;
$color-gray-3: #b9b9b9;
$text-placeholder-color-gray-4: #a2a2a2;
$text-tertiary-color-gray-5: #8b8b8b;
$color-gray-6: #737373;
$text-secondary-color-gray-7: #5c5c5c;
$color-gray-8: #454545;
$color-gray-9: #2d2d2d;
$color-gray-10: #80808f;
$color-gray-11: #f4f4f4;
$text-primary-color-gray-10: #161616;
$disabled-text-color-gray-10: #161616;
$color-background-selection: #d8edff;
$color-visited: #9747ff;
$link-water: #f4f6f9;
$brand-primary: #1589ee;
$brand-secondary: #159bd7;
$brand-accessible: #0070d2;
$brand-accessible-active: #005fb2;
$brand-accessible-focus: #0047b3;
$brand-black: #060707;
$color-text-link-active: #00396b;
$color-background-success: #4bca81;
$color-background-success-dark: #04844b;
$color-text-success: #027e46;
$color-background-success-darker: #004a29;
$color-background-warning: #ffb75d;
$color-background-warning-dark: #ff9e2c;
$color-text-warning-alt: #844800;
$color-background-error: #d4504c;
$color-background-error-dark: #c23934;
$color-background-destructive-hover: #a61a14;
$color-background-destructive-active: #870500;
$color-gray-c4: #c4c4c4;
$color-gray-3c3: #c3c3c3;
$color-disable: #cccccc;
$brand-secondary-blue: #253c7f;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
