@import "@/styles/_variables.scss";

.input-title {
  &:focus {
    border: 1px solid $color-gray-1;
    outline: none;
    border-radius: 4px;
  }
}

.wrapper {
  width: 100%;
  background: $color-white;
  padding: 8px 24px 24px;
}
.full {
  position: sticky;
  top: 116px;
  border-radius: 8px;
  padding: 24px;
  transform: translateY(-50px);
  box-shadow: 0px 1px 4px 0px rgba(199, 199, 232, 0.04),
    0px 4px 8px 0px rgba(199, 199, 232, 0.06),
    0px 8px 16px 0px rgba(199, 199, 232, 0.08),
    0px 16px 32px 0px rgba(199, 199, 232, 0.08),
    0px 32px 50px 0px rgba(199, 199, 232, 0.12),
    0px 40px 84px 0px rgba(199, 199, 232, 0.12);
}

.description {
  margin-top: 8px;
  padding: 8px 0;
  border: none;
  outline: none;
  cursor: text;
  font-size: 14px;
  word-break: break-all;
  &:empty:before {
    content: attr(placeholder);
    color: $text-placeholder-color-gray-4;
    font-size: 14px;
  }
}

.hashtag {
  width: 100%;
  color: $text-primary-color-gray-10;
  background-color: $color-white;
  border: 1px solid $color-gray-1;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 15px;
  outline: none;
  margin-top: 8px;
}
