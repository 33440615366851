@import "@/styles/_variables.scss";

.btn-add {
  color: $color-gray-1;
  border: 1px dashed $color-gray-1;
  background: $color-white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 1rem;
  }
}
