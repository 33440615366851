@import "@/styles/_variables.scss";

.header {
  position: fixed;
  top: 0;
  left: 52px;
  width: calc(100% - 52px);
  background: white;
  z-index: 2;
}

.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  height: 48px;
  border-bottom: 1px solid $color-gray-1;
}

.divider {
  width: 1px;
  height: 100%;
  color: $color-gray-1;
}

.file-active {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 1rem;
    width: calc(100% - 1rem);
    height: 2px;
    background-color: $brand-primary;
  }
}
