@import "@/styles/_variables.scss";

.task-update {
  position: fixed;
  top: 132px;
  left: calc(52px + 24px);
  z-index: 2;
  transform: translateX(0);
  transition: all 0.25s;
}

.task-update-active {
  transform: translateX(348px);
}

.toast {
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(52, 60, 68, 0.1),
    0px 0px 2px 0px rgba(117, 131, 142, 0.1);
  border: none;
}

.count {
  background: $brand-primary;
  color: $color-white;
  display: inline-flex;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid $brand-primary;
  font-size: 10px;
  font-weight: 600;
}

.wrapper-tab {
  border-bottom: 1px solid $color-gray-1;
  margin: 0 -12px;
  padding: 0 12px 0 4px;
}

.tab {
  border-bottom: 2px solid white;
  cursor: pointer;
}

.active {
  border-bottom: 2px solid $color-secondary;
}

.preview {
  background: $link-water;
  padding: 8px 8px 12px 8px;
}

.link {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: $text-primary-color-gray-10;
}
