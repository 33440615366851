@import "@/styles/_variables.scss";

.comment {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.text-box {
  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  max-height: 126px;
  overflow: auto;
}

.box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid $color-gray-1;
  padding: 12px;
  background-color: $color-white;
}

.icon {
  color: $text-tertiary-color-gray-5;
  cursor: pointer;
  font-size: 20px;
  margin-left: 8px;
}

.mention {
  position: absolute;
  bottom: 100%;
  left: 1rem;
}

.mention-item {
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: $color-gray-1;
  }
}
.active {
  background-color: $color-gray-1;
}

.preview {
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  background: $color-gray-1;
  padding: 8px 24px 8px 8px;
  gap: 2px;
}
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  font-size: 20px;
  cursor: pointer;
}

.loading {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-white;
    opacity: 0.6;
    z-index: 9;
  }
}
