@import "@/styles/_variables.scss";

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 52px;
  background: $color-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 52px);
  padding: 4px 8px;
  z-index: 2;
}

.sheet {
  border-radius: 6px;
  cursor: pointer;
  padding: 8px 16px;
  &:hover {
    background-color: var(--bs-gray-100);
  }
}
