@import "@/styles/_variables.scss";

.overlay {
  background: white;
  border-radius: 8px;
  border: 0.5px solid $color-gray-1;
  background: $color-white;
  box-shadow: 0px 2px 8px 0px rgba(52, 60, 68, 0.1),
    0px 0px 2px 0px rgba(117, 131, 142, 0.1);
  margin-bottom: 6px;
  z-index: 11;
}
