@import "@/styles/_variables.scss";

.toggleArrow {
  &::after {
    display: none !important;
  }
}
.toggle {
  &::after {
    display: none !important;
  }
}

.dropdown-wrapper {
  box-shadow: 0px 2px 4px 0px rgba(52, 60, 68, 0.16),
    0px 0px 4px 0px rgba(117, 131, 142, 0.04);
  width: 17.5rem;
  border: 1px solid $color-gray-1 !important;
}

.sub-dropdown-wrapper {
  box-shadow: 0px 2px 4px 0px rgba(52, 60, 68, 0.16),
    0px 0px 4px 0px rgba(117, 131, 142, 0.04);
  border: 1px solid $color-gray-1 !important;
}

.icon {
  color: $text-secondary-color-gray-7;
}

.text-delete {
  color: $color-background-error;
}

.item:active {
  background: none;
  color: $color-black;
}
