@import "@/styles/_variables.scss";

.tgn-input {
  &.md {
    input {
      padding: 9px 15px;
    }
    &.error,
    &.warning,
    &.password-type {
      .input-container {
        &::before {
          top: 13px;
        }
      }
    }
  }
  &.lg {
    input {
      padding: 13px 15px;
    }
    &.error,
    &.warning,
    &.password-type {
      .input-container {
        &::before {
          top: 18px;
        }
      }
    }
  }
  label,
  span {
    color: $text-secondary-color-gray-7;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  input {
    color: $text-primary-color-gray-10;
    background-color: $color-white;
    border: 1px solid $color-gray-1;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 5px 15px;
    &::placeholder {
      color: $text-placeholder-color-gray-4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    &:focus {
      background-color: $color-white;
      border-color: $brand-primary;
      box-shadow: unset;
    }
    &:active {
      border-color: $brand-accessible;
    }
    &[readonly] {
      cursor: not-allowed;
    }
  }
  &.error {
    .input-container {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        background-image: url("../../assets/icons/error.svg");
        background-size: 16px 16px;
        height: 16px;
        right: 16px;
        top: 9px;
        width: 16px;
      }
    }
    input {
      border-color: $color-background-error-dark;
      padding-right: 48px;
    }
    span {
      color: $color-background-error-dark;
    }
  }
  &.warning {
    .input-container {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        background-image: url("../../assets/icons/warning.svg");
        background-size: 16px 16px;
        height: 16px;
        right: 16px;
        top: 9px;
        width: 16px;
      }
    }
    span {
      color: $color-background-warning-dark;
    }
    input {
      padding-right: 48px;
    }
  }
  &.password-type {
    .input-container {
      position: relative;
      &::before {
        right: 48px;
      }
    }
    button {
      padding: 0;
      right: 16px;
      top: 9px;
      position: absolute;
    }
  }
  span {
    margin-top: 4px;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.search {
  input {
    padding-left: 36px !important;
  }
}
