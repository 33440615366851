@import "@/styles/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints";

.wrapper {
  min-width: 450px;
  max-width: 450px;
}

.comment {
  position: relative;
  display: flex;
  gap: 12px;
  padding: 8px;
  min-width: 412px;
  margin-bottom: 8px;
  border-radius: 4px;
  transition: all 0.25s;
  &:hover {
    background: #f9fafb;
  }
}
.dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: $text-tertiary-color-gray-5;
  margin: 0 4px;
}

.content {
  display: inline-flex;
  flex: 1;
}

.box {
  max-height: calc(100vh - 48px - 120px);
  overflow-y: auto;
}

.message {
  word-wrap: break-word;
}

.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-gray-1;
  height: 49px;
  margin-bottom: 1rem;
}

.chip-owner {
  color: #4e5ba6;
  border-radius: 16px;
  background: #eef4ff;
  display: flex;
  padding: 2px 8px 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.action {
  position: absolute;
  right: 8px;
  top: -8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border: 1px solid var(--bs-gray-100);
  background: $color-white;
  color: #475467;
  border-radius: 4px;
  i {
    font-size: 18px;
    cursor: pointer;
  }
}

.file {
  position: relative;
  display: flex;
  border-radius: 4px;
  border: 0.5px solid var(--bs-gray-100);
  background: var(--bs-gray-100);
  align-items: center;
  gap: 8px;
  padding: 12px;
  margin-bottom: 4px;
  cursor: pointer;
}
.loader {
  position: absolute;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  height: 3px;
  background: var(--bs-primary);
  width: 20px;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
