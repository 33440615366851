@import "@/styles/_variables.scss";

.wrapper {
  margin-top: 40px;
  border-bottom: 1px solid $color-gray-1;
  padding-bottom: 20px;
  margin-bottom: 2rem;
}
.title {
  border: none;
  outline: none;
  cursor: text;
  font-size: 18px;
  margin-top: 2px;
  word-break: break-all;
  min-width: 100px;
  font-weight: 600;
  flex: 1;
  &:empty:before {
    content: attr(placeholder);
    color: $text-placeholder-color-gray-4;
    font-size: 18px;
  }
  &:disabled {
    background: $color-white;
  }
}
.description {
  border: none;
  outline: none;
  cursor: text;
  font-size: 14px;
  word-break: break-all;
  &:empty:before {
    content: attr(placeholder);
    color: $text-placeholder-color-gray-4;
    font-size: 14px;
  }
}

.comment {
  border: none;
  outline: none;
  font-size: 14px;
  word-break: break-all;
  cursor: pointer;
  padding: 2px 0;
  color: var(--bs-gray-900);
}

.btn-add-task {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 30px;
  border: none;
  outline: none;
  color: $text-tertiary-color-gray-5;
  font-size: 14px;
  margin-left: 32px;
  &::before {
    position: absolute;
    content: "";
    left: -1rem;
    top: 0;
    width: 1px;
    height: 28px;
    background: $color-gray-1;
  }
}

.btn-down {
  margin-top: 5px;
}

.create-task {
  margin-top: 40px;
  margin-bottom: 80px;
}

.icon {
  position: absolute;
  right: 1rem;
  top: 2px;
  color: $text-tertiary-color-gray-5;
  font-size: 1rem;
  cursor: pointer;
  z-index: 0;
}

.commentBox {
  padding: 2px 1rem;
  margin-left: -1rem;
  position: relative;
  &:hover {
    border-radius: 8px;
    background: rgba(229, 229, 229, 0.2);
  }
}

.toggle {
  &:hover {
    text-decoration: underline;
  }
}

.comment-input {
  border: none;
  outline: none;
  cursor: text;
  font-size: 12px;
  word-break: break-all;
  flex: 1;
  border-bottom: 1px solid var(--bs-gray-400);
  margin-top: 2px;
  &:empty:before {
    content: attr(placeholder);
    color: var(--bs-gray-400);
    font-size: 12px;
  }
}

.comment-box {
  border-left: 1px solid $color-gray-1;
  padding-left: 8px;
}
