@import "@/styles/_variables.scss";

.overlay {
  width: 280px;
  background: white;
  border-radius: 8px;
  border: 0.5px solid $color-gray-1;
  background: $color-white;
  box-shadow: 0px 2px 8px 0px rgba(52, 60, 68, 0.1),
    0px 0px 2px 0px rgba(117, 131, 142, 0.1);
  padding: 1rem;
}

.setting {
  position: fixed;
  top: 132px;
  right: 76px;
  z-index: 2;
  border-radius: 100px;
  background: $color-gray-1;
}

.setting-sidebar {
  position: fixed;
  right: 0;
  height: calc(100vh - 48px - 56px);
  top: calc(48px + 56px + 1px);
  width: 360px;
  background: $color-white;
  padding: 1rem 1.5rem;
  transform: translateX(100%);
  transition: all 0.25s;
}

.show {
  transform: translateX(0);
}

.color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 4px;
}

.option-decoration {
  padding: 2px;
  display: flex;
  column-gap: 2px;
  border-radius: 4px;
  background: $color-gray-1;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    font-size: 12px;
    padding: 6px 8px;
    cursor: pointer;
  }
}

.active {
  background: $color-white;
  color: $brand-primary;
  font-weight: 500;
}

.action {
  position: absolute;
  width: 280px;
  right: 100%;
  top: 50px;
}
