@import "@/styles/_variables.scss";

.btn-add {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #19161d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: $color-white;
    font-size: 18px;
  }
}
.clear,
.cancel {
  color: $text-secondary-color-gray-7;
  border-radius: 12px;
  background: rgba(232, 232, 232, 0.8);
  display: flex;
  padding: 4px 4px 4px 8px;
  gap: 4px;
  backdrop-filter: blur(4px);
  font-size: 12px;
  cursor: pointer;
  transition: all 0.25s linear;
  font-size: 12px;
  &:hover {
    background-color: $color-white;
    span {
      color: $color-black;
    }
  }
}
.popup {
  width: 280px;
  border-radius: 8px;
  border: 0.5px solid $color-gray-1;
  background: $color-white;
  padding: 8px;
  box-shadow: 0px 2px 8px 0px rgba(52, 60, 68, 0.1),
    0px 0px 2px 0px rgba(117, 131, 142, 0.1);
  z-index: 11;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: $link-water;
  }
}
