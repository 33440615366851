@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", system-ui, sans-serif;
  }

  :root {
    --background: #fff;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: #e5e7eb;
    --input: #e5e7eb;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --brand: #1589ee;
    --brand-25: #f5fafe;
    --brand-50: #ecf5fe;
    --brand-100: #d8ecfd;
    --brand-200: #b1d8f9;
    --brand-300: #8ac4f6;
    --brand-400: #63b0f4;
    --brand-500: #3c9cf0;
    --brand-600: #1589ee;
    --brand-700: #137bd6;
    --brand-800: #116ebe;
    --brand-900: #0d528f;

    --primary: #1589ee;
    --primary-foreground: 210 40% 98%;

    --secondary: #111827;
    --secondary-foreground: #fff;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: #d92d20;
    --destructive-foreground: #fff;

    --ring: 215 20.2% 65.1%;

    --radius: 8px;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --brand: #1589ee;
    --brand-25: #f5fafe;
    --brand-50: #ecf5fe;
    --brand-100: #d8ecfd;
    --brand-200: #b1d8f9;
    --brand-300: #8ac4f6;
    --brand-400: #63b0f4;
    --brand-500: #3c9cf0;
    --brand-600: #1589ee;
    --brand-700: #137bd6;
    --brand-800: #116ebe;
    --brand-900: #0d528f;

    --primary: #111827;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 8px;
  }

  .locked {
    --brand: #111827;
    --brand-25: #fdfeff;
    --brand-50: #f9fafb;
    --brand-100: #f3f4f6;
    --brand-200: #e5e7eb;
    --brand-300: #d1d5db;
    --brand-400: #9ca3af;
    --brand-500: #6b7280;
    --brand-600: #4b5563;
    --brand-700: #374151;
    --brand-800: #1f2937;
    --brand-900: #111827;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e4e4e7 var(--background);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--background);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e4e4e7;
  border-radius: 14px;
  border: 3px solid var(--background);
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}
.node{
  background-color: #e4e4e7;
  border-radius: 14px;
  border: 3px solid var(--background);
}