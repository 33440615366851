@import "@/styles/_variables.scss";

.editable {
  border: none;
  outline: none;
  cursor: text;
  &:empty:before {
    content: attr(placeholder);
    color: $text-placeholder-color-gray-4;
    font-size: 1em;
  }
}

.title {
  display: block;
  font-size: 1rem;
  font-weight: 600 !important;
  margin-bottom: 1rem;
  border: none !important;
  outline: none;
  padding-left: 0 !important;
}

.description {
  font-size: 14px !important;
  min-height: 96px !important;
  max-height: 500px;
  overflow-y: auto;
  border: none !important;
  outline: none !important;
  width: 100%;
  padding-left: 0 !important;
  &:focus {
    box-shadow: none !important;
  }
}

.btn-add {
  color: $color-gray-1;
  border: 1px dashed $color-gray-1;
  background: $color-white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 1rem;
  }
}
