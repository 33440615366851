@import "@/styles/_variables.scss";

.menuBar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  bottom: 4rem;
  left: 0;
  width: 100%;
  column-gap: 8px;
}

.dropdown {
  background: $color-gray-11;
  border: none;
  color: $color-black;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  a {
    padding: 0 24px;
    height: 48px;
    display: flex;
    align-items: center;
  }
  &-title {
    margin-bottom: 0;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
  }
}

.actions {
  background: $color-white;
  color: $color-black;
  border-radius: 100px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  border: 0.5px solid var(--bs-gray-100);
  box-shadow: 0px 1px 4px 0px rgba(199, 199, 232, 0.04),
    0px 4px 8px 0px rgba(199, 199, 232, 0.06),
    0px 8px 16px 0px rgba(199, 199, 232, 0.08),
    0px 16px 32px 0px rgba(199, 199, 232, 0.08),
    0px 32px 50px 0px rgba(199, 199, 232, 0.12),
    0px 40px 84px 0px rgba(199, 199, 232, 0.12);
}

.icon {
  display: flex;
  height: 48px;
  align-items: center;
}

.btn-icon {
  &:hover {
    background: $color-white !important;
  }
}
