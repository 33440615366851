@import "@/styles/_variables.scss";

.toast {
  width: 320px;
  position: relative;
  border-radius: 8px;
  border: 0.5px $color-gray-1;
  background: rgba(232, 232, 232, 0.8);
  backdrop-filter: blur(4px);
  padding: 1rem 12px;
  display: flex;
  align-items: center;
  min-width: 300px;
  gap: 8px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: $color-white;
  }
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  transform: translate(-40%, -40%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.show {
  opacity: 1;
  visibility: visible;
}

.search {
  display: flex;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: $color-white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  i {
    color: #19161d;
  }
}

.load {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel {
  background: rgba(232, 232, 232, 0.8);
  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
}

.disableText {
  color: $text-tertiary-color-gray-5 !important;
}

.thumbnail {
  width: 32px;
}

.dropzone {
  border-radius: 8px;
  border: 1px dashed $color-gray-1;
  padding: 48px 0;
  margin-top: 1.5rem;
}

.drag-active {
  border: 1px dashed $brand-primary;
}
