@import "@/styles/_variables.scss";

.wrapper {
  border-radius: 8px;
  border: 0.5px solid #eaecf0;
  background: $color-white;
  padding: 1rem 8px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.item {
  display: flex;
  padding: 8px;
  transition: all 0.2s;
  border-radius: 4px;
  cursor: pointer;
  span:nth-child(2) {
    color: #98a2b3;
  }
  &:hover {
    background: var(--bs-gray-100);
  }
}
